export const options_caso = [{
    value: 0,
    nome: "Nessun Caso Selezionato",

},
{
    value: "PATERNA",
    nome: "PATERNA",

},
{
    value: "MATERNA",
    nome: "MATERNA",

},
{
    value: "MISTO",
    nome: "MISTO",

}

];
