import { api } from "./axios";
import configData from "../data/configuration.json"
import configDataDev from "../data/configuration_dev.json"
import configDataTest from "../data/configuration_test.json"


let manifestdata;

if (process.env.NODE_ENV === "production") {
    manifestdata = configData
}
if (process.env.NODE_ENV === "development") {
    manifestdata = configDataDev
}
if (process.env.NODE_ENV === "test") {
    manifestdata = configDataTest
}
export async function fetchManifest() {
    let manifestname = ''
    if (process.env.NODE_ENV === "production") {
        manifestname = 'manifest.json'
    }
    if (process.env.NODE_ENV === "development") {
        manifestname = 'manifest.json'
    }
    const response = await fetch(manifestname)

    const manifest = await response.json()

    window.manifest = manifest

}


export const praticheGetAll = async (user) => {
    try {
        let url
        //  if (window.manifest && window.manifest.base_url) {

        url = `/pratiche`

        const response = await (await api.get(url)).data

        console.log("res praticheGetAll", response)
        if (response) {
            return response.sort((a, b) => b.idPratica - a.idPratica)

        } else {
            const response = {
                errore: "true",
                messaggio: `C'è un errore nel caricamento dei dati`
            }
            return response
        }
        /*    }
           else {
               console.log("window.manifest", window.manifest)
   
               const response = {
                   errore: "true",
                   messaggio: `C'è un errore nel caricamento dei dati`
               }
               return response
           } */



    } catch (error) {
        console.log("error getLetture", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}


export const exportData = async (user) => {
    try {
        let url

        url = `/pratiche`

        const response = await (await api.get(url)).data

        console.log("res exportData", response)
        if (response) {
            return response.sort((a, b) => b.idPratica - a.idPratica)

        } else {
            const response = {
                errore: "true",
                messaggio: `C'è un errore nel caricamento dei dati`
            }
            return response
        }
  
    } catch (error) {
        console.log("error getLetture", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}



export const getPraticaById = async (idPratica) => {
    
    try {

        let url = `/pratiche?idPratica=eq.${idPratica}`

        const response = await (await api.get(url)).data

        console.log("res getPraticaById", response)
        // const r = await getDetailsById (idPratica)
        return response[0]
    } catch (error) {
        console.log("error getPraticaById", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}
export const getDetailsById = async (idPratica) => {
    
    try {

        let url = `/secret_data?idPratica=eq.${idPratica}`

        const response = await (await api.get(url)).data

        console.log("res getDetailsById", response)

        return response[0]
    } catch (error) {
        console.log("error getDetailsById", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const updatePratica = async (pratica, id) => {

    console.log("oggetto pratica nel service", pratica)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/pratiche?idPratica=eq.${id}`

        const response = await (await api.patch(url, pratica, config)).data

        console.log("res updatePratica", response[0])

        let risposta;
        if (response && response[0] && response[0].idPratica) {
            risposta = {
                errore: "false",
                messaggio: `Pratica modificata correttamente`
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'operazione`
            }
        }

        return risposta

    } catch (error) {
        console.log("error updatePratica", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const updateDetails = async (pratica, id) => {

    console.log("oggetto pratica nel service", pratica)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/secret_data?idPratica=eq.${id}`

        const response = await (await api.patch(url, pratica, config)).data

        console.log("res updateDetails", response[0])

        let risposta;
        if (response && response[0] && response[0].idPratica) {
            risposta = {
                errore: "false",
                messaggio: `Pratica modificata correttamente`
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'operazione`
            }
        }

        return risposta

    } catch (error) {
        console.log("error updateDetails", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const insertPratica = async (pratica) => {

    console.log("oggetto pratica nel service", pratica)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/pratiche`

        const response = await (await api.post(url, pratica, config)).data

        console.log("res insertPratica", response[0])
   
        let risposta;
        if (response && response[0] && response[0].idPratica) {
            risposta = {
                errore: "false",
                messaggio: response[0]
            }
                let newPraticaDetails = {
                caso:  '',
                responsabile:  '',
                dataIscrizione:  new Date().toISOString(),
                tribunale:  '',
                giudice:  '',
                udienza:  '',
                note:  '',
                idPratica:response[0].idPratica
                }
        const r = await insertPraticaDetails( newPraticaDetails)
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'operazione`
            }
        }

        return risposta


    } catch (error) {
        console.log("error insertPratica", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}
export const insertPraticaDetails = async (pratica) => {

    console.log("oggetto pratica nel service", pratica)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }

        };
        let url = `/secret_data`

        const response = await (await api.post(url, pratica, config)).data

        console.log("res insertPratica", response[0])

        let risposta;
        if (response && response[0] && response[0].idPratica) {
            risposta = {
                errore: "false",
                messaggio: response[0]
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'operazione`
            }
        }

        return risposta


    } catch (error) {
        console.log("error insertPratica", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const getAdempimentiByIdPratica = async (idPratica) => {
    try {

        let url = `/adempimenti?idPratica=eq.${idPratica}`

        const response = await (await api.get(url)).data

        console.log("res getAdempimentiByIdPratica", response)
        if (response) {


            const risposta = response.filter((ad) => ad.deleted === null)
            console.log("risposta filtrata senza eliminati", risposta)

            return risposta.sort((a, b) => b.id_adempimento - a.id_adempimento)


        } else {
            const response = {
                errore: "true",
                messaggio: `C'è un errore nel caricamento dei dati`
            }
            return response
        }

    } catch (error) {
        console.log("error getAdempimentiByIdPratica", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const insertAdempimento = async (adempimento) => {
    console.log("oggetto adempimento nel service", adempimento)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/adempimenti`
        const response = await (await api.post(url, adempimento, config)).data

        console.log("res insertadempimento", response[0])

        let risposta;
        if (response && response[0] && response[0].id_adempimento) {
            risposta = {
                errore: "false",
                messaggio: `Adempimento aggiunto correttamente`,
                adempimento:response[0]
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'operazione`
            }
        }

        return risposta

    } catch (error) {
        console.log("error insertadempimento", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const updateAdempimento = async (adempimento, id) => {
    console.log("oggetto adempimento nel service", adempimento)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/adempimenti?id_adempimento=eq.${id}`
        console.log("url patch", url)
        const response = await (await api.patch(url, adempimento, config)).data

        console.log("res updateAdempimento", response[0])
        let risposta;
        if (response && response[0] && response[0].id_adempimento) {
            risposta = {
                errore: "false",
                messaggio: `Adempimento eliminato correttamente`
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'operazione`
            }
        }

        return risposta
    } catch (error) {
        console.log("error updateAdempimento", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const getJsonCertificati = async () => {
    try {
        let url

        url = `/certificati`

        const response = await (await api.get(url)).data

        if (response) {
            return response
        } else {
            const response = {
                errore: "true",
                messaggio: `C'è un errore nel caricamento dei dati dei certificati`
            }
            return response
        }

    } catch (error) {
        console.log("error getCertificati", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const getJsonCittadinanze = async () => {
    try {
        let url

        url = `/cittadinanze`

        const response = await (await api.get(url)).data

        if (response) {
            return response
        } else {
            const response = {
                errore: "true",
                messaggio: `C'è un errore nel caricamento dei dati delle cittadinanze`
            }
            return response
        }

    } catch (error) {
        console.log("error getCittadinanze", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}