import {
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableCaption,
    TableContainer,
    Heading,
    Stack,
    Box,
    Select,
    Card,
    Text,
    CardHeader,
    IconButton,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    Stepper,
    useSteps,
    FormControl,
    FormLabel,
    Input,
    Button,
    Flex,
    Center,
    useToast,
    Textarea, Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, Lorem, ModalFooter
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import { updateDetails, getDetailsById } from "../services/pratiche_service";
import {
    Formik, Form, Field,
} from "formik";
import {
    useParams
  } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { options_responsabile } from "../data/options_responsabile";
import { options_tribunale } from "../data/options_tribunale";
import { options_caso } from "../data/options_caso";



function DetailModal(props) {

    // const { onClose } = useDisclosure()
    const [caso, setCaso] = useState('')
    const [responsabile, setResponsabile] = useState('')
    const [dataIscrizione, setDataIscrizione] = useState(null)
    const [tribunale, setTribunale] = useState('')
    const [giudice, setGiudice] = useState('')
    const [udienza, setUdienza] = useState('')

    const [note, setNote] = useState('')
    const toast = useToast();
    const statuses = ['success', 'error', 'warning', 'info']
    const navigate = useNavigate();
    let { id } = useParams()
    let idUrl = id.split('&')[0]

    // const handleClose = () => {onClose((x) => !x)}

    const showToast = (title, description, status) => {
        toast({
          title: title,
          description: description,
          duration: 5000,
          isClosable: true,
          status: status
        })
      }

      const getDetails = async () => {
        const respPratica = await getDetailsById(id)
    
        if (respPratica.errore && respPratica.errore === 'true') {
          if (respPratica.messaggio.includes("401") || respPratica.messaggio.includes("403")) {
            showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
              navigate('/');
        localStorage.removeItem('token')
          } else {
            showToast(respPratica.messaggio, '', statuses[1])
    
          }
        } else {
  
          await setCaso(respPratica.caso)
          await setResponsabile(respPratica.responsabile)
          await setDataIscrizione(respPratica.dataIscrizione)
          await setTribunale(respPratica.tribunale)
          await setGiudice(respPratica.giudice)
          await setUdienza(respPratica.udienza)
          await setNote(respPratica.note)
        }
    
      }
    const onUpdateDetailPratica = async (detailPratica) => {

        console.log("detailPratica", detailPratica)
        try {
            const res = await updateDetails(detailPratica, idUrl)
             console.log("res onUpdateDetailPratica", res)
            //  handleClose()
            if (res.errore === "false") {
              
              showToast(res.messaggio, '', statuses[0])
            //   handleClose()
            } else {
              if (res.messaggio.includes("401") || res.messaggio.includes("403")) {
                showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
                  navigate('/');
          localStorage.removeItem('token')
              } else {
                showToast(res.messaggio, '', statuses[1])
      
              }
            }
      
          } catch (error) {
            showToast(error.messaggio, '', statuses[1])
          }

    }

    const convertDateToLocale = (data) => {
        if (data && data !== null) {
            return data.split('T')[0].toString()

        }
    }
    useEffect(()=>{
        getDetails()
    }, [])
  const  handleClose =()=>{props.onClose(false)}

    

    return (

        <>
            <Modal size={'4xl'} closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Altri Dati Cliente</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                caso: caso,
                                responsabile: responsabile,
                                dataIscrizione: dataIscrizione,
                                tribunale: tribunale,
                                giudice: giudice,
                                udienza: udienza,
                                note: note
                            }}
                            onSubmit={(values, actions) => {
          
                                let detailPratica = {
                                    caso: values.caso,
                                    responsabile: values.responsabile,
                                    dataIscrizione: values.dataIscrizione,
                                    tribunale: values.tribunale,
                                    giudice: values.giudice,
                                    udienza: values.udienza,
                                    note: values.note,
                                    // idPratica: idUrl
                                }
  
                                actions.setSubmitting(true)
                                setCaso(values.caso)
                                setResponsabile(values.responsabile)
                                setDataIscrizione(values.dataIscrizione)
                                setTribunale(values.tribunale)
                                setGiudice(values.giudice)
                                setUdienza(values.udienza)
                                setNote(values.note)
                                onUpdateDetailPratica(detailPratica)
                                actions.resetForm()



                            }}
                        >
                            {(props) => (
                                <Form direction='row'>
                                    <Flex alignItems={'center'}>
                                        <Field name='caso' >
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.caso && form.touched.caso}>
                                                    <FormLabel> Caso

                                                    </FormLabel>
                                                    <Select
                                                        focusBorderColor={"#584034"} bg={'lightcyan.100'}
                                                        {...field}
                                                        defaultValue={caso}
                                                        id={caso}
                                                        key={caso}

                                                        onChange={(e) => {
                                                            console.log("caso select", e)
                                                            setCaso(e.target.value)
                                                        }}>
                                                        {options_caso.map((option) => {
                                                            return <option value={option.value}>{option.nome}</option>
                                                        })}

                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='responsabile' >
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.responsabile && form.touched.responsabile}>
                                                    <FormLabel> Responsabile

                                                    </FormLabel>
                                                    <Select
                                                        focusBorderColor={"#584034"} bg={'lightcyan.100'}
                                                        {...field}
                                                        defaultValue={responsabile}
                                                        id={responsabile}
                                                        key={responsabile}

                                                        onChange={(e) => {
                                                            console.log("responsabile select", e)
                                                            setResponsabile(e.target.value)
                                                        }}>
                                                        {options_responsabile.map((option) => {
                                                            return <option value={option.value}>{option.nome}</option>
                                                        })}

                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Flex>

                                    <Flex alignItems={'center'}>
                                        <Field name='dataIscrizione' >
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.dataIscrizione && form.touched.dataIscrizione}>
                                                    <FormLabel>Iscrizione al Ruolo

                                                    </FormLabel>
                                                    <Input focusBorderColor={"#C69E81"} type="date" defaultValue={convertDateToLocale(dataIscrizione)} onChange={(e) => {
                                                        e.preventDefault()
                                                        console.log("data", e)
                                                        setDataIscrizione(e.target.value)
                                                    }}  {...field} placeholder='Data' />
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='tribunale' >
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.tribunale && form.touched.tribunale}>
                                                    <FormLabel> Tribunale

                                                    </FormLabel>
                                                    <Select
                                                        focusBorderColor={"#584034"} bg={'lightcyan.100'}
                                                        {...field}
                                                        defaultValue={tribunale}
                                                        id={tribunale}
                                                        key={tribunale}

                                                        onChange={(e) => {
                                                            console.log("tribunale select", e)
                                                            setTribunale(e.target.value)
                                                        }}>
                                                        {options_tribunale.map((option) => {
                                                            return <option value={option.value}>{option.nome}</option>
                                                        })}

                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Flex>
                                        <Flex
                                        alignItems={"end"}>
                                        <Field name='giudice'>
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.giudice && form.touched.giudice}>
                                                    <FormLabel>Giudice </FormLabel>
                                                    <Input focusBorderColor={"#C69E81"} value={giudice} {...field} placeholder='Giudice' />
                                                </FormControl>
                                            )}
                                        </Field>
                                        </Flex>
                                    <Flex
                                        alignItems={"end"}>
                                        <Field name='udienza'>
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.udienza && form.touched.udienza}>
                                                    <FormLabel>Udienza </FormLabel>
                                                    <Textarea focusBorderColor={"#C69E81"} value={udienza} {...field} placeholder='Udienza' />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Flex>
                                    <Flex
                                        alignItems={"end"}>
                                        <Field name='note'>
                                            {({ field, form }) => (
                                                <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.note && form.touched.note}>
                                                    <FormLabel>Note </FormLabel>
                                                    <Textarea focusBorderColor={"#C69E81"} value={note} {...field} placeholder='Note Aggiuntive' />
                                                </FormControl>
                                            )}
                                        </Field>

                                    </Flex>

                                    <Center>
                            <Button onClick={handleClose}>Annulla</Button>

                            <Button
                                _hover={{ bg: "#8a4828" }}
                                ml={2}
                                background={'#584034'} color={'white'}
                                loadingText='Compila i campi per salvare'
                                type='submit'
                            >
                                Salva
                            </Button>
                        </Center>

                                </Form>
                            )}
                        </Formik>
                        
    
                    </ModalBody>

                    {/* <ModalFooter>


                    </ModalFooter> */}
                </ModalContent>
            </Modal>


        </>


    )

}

export default DetailModal;