import { api, apiLogin } from "./axios";
import configData from "../data/configuration.json"
import configDataDev from "../data/configuration_dev.json"
import configDataTest from "../data/configuration_test.json"


let manifestdata;

if (process.env.NODE_ENV === "production") {
    manifestdata = configData
}
if (process.env.NODE_ENV === "development") {
    manifestdata = configDataDev
}
if (process.env.NODE_ENV === "test") {
    manifestdata = configDataTest
}




export const tryLogin = async (datiUtente) => {
    console.log("parametri tryLogin nel service", datiUtente)

    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        console.log("process.env.PL_BASE_URL",process.env.REACT_APP_BASE_URL )
        let url = `/rpc/login `
        let objToSend = {
            email:datiUtente.username,
            pass:datiUtente.password
        }
        const response = await (await apiLogin.post(url, objToSend, config)).data

       console.log("res tryLogin", response)

        let risposta;
        if (response && response.token) {
            risposta = {
                errore: "false",
                messaggio: true,
                token:response.token
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `Username e password errati`
            }
        }

       // console.log("risposta tryLogin", risposta)

        return risposta

    } catch (error) {
        console.log("error tryLogin", error)
        let risposta 
        if(error.toString().includes("403")){
            console.log("presa")
            risposta = {
                errore: "true",
                messaggio: `Username e password errati`
            }
        } else {
           risposta = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        } 
        }
        return risposta
    } 
}