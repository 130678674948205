import {
    Tr,
    Td,
    Text,
    Box,
    WrapItem,
    Center
} from "@chakra-ui/react";
import { useState } from "react";
import { dateConverterReadOnly } from "../services/utilities";
import { tipi_adempimento } from "../data/tipi_adempimento";



function AdempimentiRowReadOnly(props) {

    const convertDateToLocale = (data) => {
        return data.split('T')[0].toString()
    }
    return (
        <>
            <Tr key={props.idAdempimento + 'rowAdempimenti'}>
                <Td w={'sm'} maxWidth={'200px'} key={props.idAdempimento + 'data'}  className={'largeTable'}>
                    {dateConverterReadOnly(props.dataA.toString())}
                </Td>
                <Td w={'sm'} maxWidth={'300px'} key={props.idAdempimento + 'tipoAdempimento'} className={'largeTable'}>
                    {(tipi_adempimento.find((a) => a.value === parseInt(props.tipoAdempimento))) ? tipi_adempimento.find((a) => a.value === parseInt(props.tipoAdempimento)).nome : tipi_adempimento[0].nome}
                </Td>
                <Td key={props.idAdempimento + 'adempimento'} className={'largeTable'}>
                    {(props.adempimento !== '') ? props.adempimento : 'Nessun adempimento salvato'}
                </Td>

            </Tr>
                <Center  maxW='sm'h={'4rem'}  background={'#C69E81'} className={'responsiveTable'}>
                <Text className={'responsiveTable'} style={{ whiteSpace: 'pre-line' }}> Data: {dateConverterReadOnly(props.dataA.toString())}</Text>

                </Center>
 

            <Box maxW='sm' className={'responsiveTable'}>
                <Text className={'responsiveTable'} style={{ whiteSpace: 'pre-line' }}> Attività:   {(tipi_adempimento.find((a) => a.value === parseInt(props.tipoAdempimento))) ? tipi_adempimento.find((a) => a.value === parseInt(props.tipoAdempimento)).nome : tipi_adempimento[0].nome}</Text>
            </Box>
            <Box maxW='sm' className={'responsiveTable'}>
                <Text className={'responsiveTable'} style={{ whiteSpace: 'pre-line' }}> Adempimento:   {(props.adempimento !== '') ? props.adempimento : 'Nessun adempimento salvato'}</Text>

            </Box>





        </>
    );
}

export default AdempimentiRowReadOnly;

