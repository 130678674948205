export const options_tribunale = [{
  value: 0,
  nome: "Nessun Tribunale Selezionato",
},
{
  value: "ANCONA",
  nome: "ANCONA",
},
{
  value: "BARI",
  nome: "BARI",
},
{
  value: "BOLOGNA",
  nome: "BOLOGNA",
},
{
  value: "BRESCIA",
  nome: "BRESCIA",
},
{
  value: "CAGLIARI",
  nome: "CAGLIARI",
},
{
  value: "CALTANISSETTA",
  nome: "CALTANISSETTA",
},
{
  value: "CAMPOBASSO",
  nome: "CAMPOBASSO",
},
{
  value: "CATANIA",
  nome: "CATANIA",
},
{
  value: "CATANZARO",
  nome: "CATANZARO",
},
{
  value: "FIRENZE",
  nome: "FIRENZE",
},
{
  value: "GENOVA",
  nome: "GENOVA",
},
{
  value: "L'AQUILA",
  nome: "L'AQUILA",
},
{
  value: "LECCE",
  nome: "LECCE",

},
{
  value: "MESSINA",
  nome: "MESSINA",
},
{
  value: "MILANO",
  nome: "MILANO",
},
{
  value: "NAPOLI",
  nome: "NAPOLI",
},
{
  value:  "PALERMO",
  nome: "PALERMO",
},
{
  value:"PERUGIA",
  nome: "PERUGIA",
},
{
  value: "POTENZA",
  nome: "POTENZA",
},
{
  value: "REGGIO CALABRIA",
  nome: "REGGIO CALABRIA",
},
{
  value: "ROMA",
  nome: "ROMA",
},
{
  value: "SALERNO",
  nome: "SALERNO",
},
{
  value: "TORINO",
  nome: "TORINO",
},
{
  value: "TRENTO",
  nome: "TRENTO",
},
{
  value: "TRIESTE",
  nome: "TRIESTE",
},
{
  value: "VENEZIA",
  nome: "VENEZIA",
}
];