import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  TableContainer,
  Heading,
  Stack,
  Card,
  Link,
  CardHeader,
  IconButton,
  Flex,
  FormControl, 
  Input,
  Highlight,
  Center,
  Button,
  Spacer
} from "@chakra-ui/react";

import { FaChevronLeft, FaChevronRight, FaEdit, FaPlus, FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { praticheGetAll, insertPratica, getJsonCertificati, getJsonCittadinanze } from "../services/pratiche_service";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { executeFilter } from "../services/utilities";
import ReactPaginate from "react-paginate";
import ExcelJS from 'exceljs';
import { dateConverterReadOnly } from "../services/utilities";
import CsvDownloadButton from 'react-json-to-csv'
import {
  useParams
} from "react-router-dom";

function Pratiche() {
  const [pratiche, setPratiche] = useState([])
  const [searchString, setSearchString] = useState('')

  const toast = useToast();
  const statuses = ['success', 'error', 'warning', 'info']
  const [token, setToken] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10); // Numero di pratiche per pagina

  let { id } = useParams()
  // let idUrl = id.split('&')[0]

  const [cittadinanze, setCittadinanze] = useState(undefined)
  const [certificati, setCertificati] = useState(undefined)

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const offset = currentPage * perPage;
  const pageCount = Math.ceil(pratiche.length / perPage);

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [token])


  const handleLogout = (e) => {
    e.preventDefault();
    navigate('/');
    localStorage.removeItem('token')
  }

  const showToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      duration: 5000,
      isClosable: true,
      status: status
    })
  }


  const getData = async () => {
    const resp = await praticheGetAll()
    console.log("pratiche in pagina", resp)
    if (!resp || resp.errore === 'true') {
      showToast(resp.messaggio, '', statuses[1])
    } else {
      const rispostaFiltrata = executeFilter(resp, {
        fieldList: ['numeroProtocollo', 'nomeCliente'],
        searchString: searchString
      })

      setPratiche(rispostaFiltrata)
    }

  }

  useEffect(() => {
    getData()
    getCittadinanze()
    getCertificati()
  }, [searchString])

  const onNewPratica = async () => {

    let newPratica = {
      numeroProtocollo: '',
      stato: 1,
      nomeCliente: '',
      emailCliente: '',
      note: ''
    }
    const response = await insertPratica(newPratica)

    console.log("risposta insert", response)
    if (response.errore === 'true') {
      showToast(response.messaggio, '', statuses[1])
    } else {
      navigate('/pratica/' + response.messaggio.idPratica + '&' + response.messaggio.stato);
      // onNewPraticaDetails(response.messaggio.idPratica)

    }
  }

  const onExportFile = async () => {
    console.log("pratiche", pratiche);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Pratiche');

    // Stile per l'intestazione della tabella
    const headerStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '584034' }, // Colore rosso
      },
      font: {
        color: { argb: 'FFFFFF' }, // Colore del testo bianco
        bold: true, // Testo in grassetto
      },
    };

    // Aggiungi intestazioni con lo stile personalizzato
    sheet.addRow(['Numero Protocollo', 'Nome Cliente', 'Email', 'Data Apertura Pratica'], 'n').eachCell((cell) => {
      cell.style = headerStyle;
    });

    sheet.addRow(['', '', '', ''])
    // Aggiungi dati delle pratiche
    pratiche.forEach(pratica => {
      sheet.addRow([pratica.numeroProtocollo, pratica.nomeCliente, pratica.emailCliente, dateConverterReadOnly(pratica.creationDate)]);
    });

    // Salva il file
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'pratiche.xlsx';
      link.click();
      window.URL.revokeObjectURL(url);
    }).catch(err => {
      console.error('Errore durante la creazione del file Excel:', err);
    });
  };



  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('user')
    console.log("user",user)
    if (user === 'view_cliente') {
      navigate('/');
      localStorage.removeItem('token')
      localStorage.removeItem('user')

    }
  }, [pratiche]);

  const getCittadinanze = async () => {
    setCittadinanze( await getJsonCittadinanze() )
  }

  const getCertificati = async () => {
    setCertificati( await getJsonCertificati() )
  }

  const pratiche_data = pratiche.slice(offset, offset + perPage).map((d) => {


    return (
      <Tr key={d.idPratica} >
        <Td>
          <Highlight query={searchString} styles={{ px: '1', py: '1', bg: 'orange.200' }}>
            {d.numeroProtocollo}
          </Highlight>
        </Td>
        <Td>
          <Highlight query={searchString} styles={{ px: '1', py: '1', bg: 'orange.100' }}>
            {d.nomeCliente}
          </Highlight></Td>
        <Td>
          <IconButton _hover={{ bg: "#8a4828" }} background={'#584034'} color={'white'} icon={<FaEdit></FaEdit>} onClick={() => { navigate('/pratica/' + d.idPratica + '&' + d.stato) }}></IconButton>
        </Td>
      </Tr>
    )
  }
  );



  return (

    <Stack w='100vw' h='100vh' >
      <Card size='lg' w='100wv' h='100hv' >
        {(token && token !== '' && token !== undefined) ? <Stack alignItems={'end'} bgColor={'#584034'} p={5}>
          <Link pe={5} color={'white'} href="#" onClick={(e) => { handleLogout(e) }}>Disconnetti</Link>
        </Stack> : <></>}
        <Stack
          flexDir="row"
          justifyContent={"center"}
          alignItems={"center"}
        >
        <CardHeader align='center' >
          <Heading size='lg'>Pratiche</Heading>
        </CardHeader>
        <IconButton _hover={{ bg: "#8a4828" }} background={'#584034'} color={'white'} icon={<FaDownload></FaDownload>} onClick={() => {  }} style={{position: 'absolute', right:'3rem'}}></IconButton>
        </Stack>
        <Stack
          flexDir="row"
          justifyContent="start"
          paddingLeft='30px'
          paddingRight='30px'
        >

          <Center>
            <Text fontSize='md'>Ricerca</Text>
          </Center>

          <FormControl w={300} >

            <Input focusBorderColor={"#C69E81"} value={searchString} onChange={(e) => {
              e.preventDefault()
              setSearchString(e.target.value)
            }} placeholder='Numero di Protocollo' />

          </FormControl>
            
          <IconButton _hover={{ bg: "#8a4828" }} background={'#584034'} color={'white'} icon={<FaPlus></FaPlus>} onClick={() => { onNewPratica() }}></IconButton>
          {/* <IconButton _hover={{ bg: "#8a4828" }} background={'#584034'} color={'white'} icon={<FaDownload></FaDownload>} onClick={() => { onExportFile() }}></IconButton> */}

          <Spacer/>

          <CsvDownloadButton 
            w={150}
            _hover={{ bg: "#8a4828" }}
            filename="cittadinanze.csv"
            delimiter=";"
            data={cittadinanze} 
            background={'#584034'} color={'white'}
            style={{ 
              all: 'inherit'
            }}
          > 

            <Button w={150} 
                _hover={{ bg: "#8a4828" }}
                background={'#584034'} color={'white'}
              >
              PROCESSI
            </Button> 

          </CsvDownloadButton>

          <CsvDownloadButton 
            w={150}
            _hover={{ bg: "#8a4828" }}
            filename="certificati.csv"
            delimiter=";"
            data={certificati} 
            background={'#584034'} color={'white'}
            style={{ 
              all: 'inherit'
            }}
          > 

            <Button w={150} 
                _hover={{ bg: "#8a4828" }}
                background={'#584034'} color={'white'}
              >
              TRASCRIZIONI
            </Button> 

          </CsvDownloadButton>

        </Stack>

        <Flex flexDirection={'column'}>

          <TableContainer w='100vw' overflowY={'block'} padding='40px' pb={'10px'}>

            <Table variant="striped" colorScheme="brand">
              <Thead>
                <Tr>
                  <Th>N.Protocollo</Th>
                  <Th>Cliente</Th>
                  <Th> Azioni</Th>
                </Tr>
              </Thead>
              <Tbody>{pratiche_data}</Tbody>
              {/* <Tfoot>
              <Tr>
                <Th>To convert</Th>
                <Th>into</Th>
                <Th isNumeric>multiply by</Th>
              </Tr>
            </Tfoot> */}
            </Table>
          </TableContainer>
          <Center>
            <ReactPaginate
              previousLabel={<FaChevronLeft color={'#584034'} className="arrow-icon"></FaChevronLeft>}
              nextLabel={<FaChevronRight color={'#584034'} className="arrow-icon"></FaChevronRight>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination horizontal-pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active-pagination-item"}

            />
          </Center>
        </Flex>




      </Card>
    </Stack>
  );
}

export default Pratiche;
