import {
    api
} from "./axios";

import configData from "../data/configuration.json"
import configDataDev from "../data/configuration_dev.json"
import configDataTest from "../data/configuration_test.json"

let manifestdata;

if (process.env.NODE_ENV === "production") {
    manifestdata = configData
}
if (process.env.NODE_ENV === "development") {
    manifestdata = configDataDev
}
if (process.env.NODE_ENV === "test") {
    manifestdata = configDataTest
}

export const sendNotificationToClient = async (idPratica) => {

    let today = new Date().toISOString()
    let praticaToSend = {
        idPratica: parseInt(idPratica),
        dataInserimento: today,
        dataInvio:null

    }
    console.log("oggetto notificaEmail nel service", praticaToSend)
    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/backlog_email`

        const response = await (await api.post(url, praticaToSend, config)).data

        console.log("res sendNotificationToClient", response[0])
        let risposta;
        if (response && response[0] && response[0].idPratica) {
            risposta = {
                errore: "false",
                messaggio: `Notifica Inviata correttamente`
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `C'è stato un errore nell'invio della notifica`
            }
        }

        return risposta


    } catch (error) {
        console.log("error sendNotificationToClient", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}

export const countingVisits = async (idPratica, guid) => {
    let today = new Date().toISOString()
    let lastcount;

    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
            }
        };
        let url = `/backlog_email?guid=eq.${guid}`

        const responseGet = await (await api.get(url, config)).data

        console.log("res countingVisits sendNotificationToClient", responseGet)
        let risposta;
        if (responseGet && responseGet[0].idPratica) {

            lastcount = (responseGet[0].contatoreVisita !== null) ?responseGet[0].contatoreVisita: 0 
            try {

                let praticaToSend = {
                    idPratica: parseInt(idPratica),
                    contatoreVisita: (parseInt(lastcount) + 1),
                    dataUltimaVisita: today
            
                }
                console.log("oggetto notificaEmail nel service", praticaToSend)


                let urlpatch = `/backlog_email?guid=eq.${guid}`
                const res = await api.patch(urlpatch, praticaToSend, config)
                console.log("res countingVisits", res)


                if (res) {
                    risposta = {
                        errore: "false",
                        messaggio: `Counting incrementato correttamente`
                    }
                } else {
                    risposta = {
                        errore: "true",
                        messaggio: `C'è stato un errore nell'operazione`
                    }
                }
            } catch (error) {

                risposta = {
                    errore: "true",
                    messaggio: `C'è stato un errore nell'operazione: ${error}`
                }

            }


            return risposta
        } else {
            const response = {
                errore: "true",
                messaggio: `C'è stato un errore`
            }
            return response
  
        }

    } catch (error) {
        console.log("error sendNotificationToClient", error)
        const response = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }
        return response
    }
}