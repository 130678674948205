export const options_responsabile = [{
  value: 0,
  nome: "Nessun Responsabile Selezionato",

},
{
  value: "SAITTA",
  nome: "SAITTA",

},
{
  value: "NARDOCCI",
  nome: "NARDOCCI",

},
{
  value: "DE SIMONE",
  nome: "DE SIMONE",

}

];
