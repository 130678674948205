import {
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableCaption,
  TableContainer,
  Heading,
  Stack,
  Box,
  Select,
  Card,
  Text,
  CardHeader,
  IconButton,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  useSteps,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Center,
  useToast,
  Textarea, Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, Lorem, ModalFooter, Grid, GridItem
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Formik, Form, Field, ErrorMessage,
} from "formik";
import emailjs from '@emailjs/browser';
import { pratiche } from "../data/pratiche";
import { FaEdit, FaRegFolderOpen, FaPlus, FaArrowRight, FaPen, FaAngleRight,FaRegStickyNote, FaArrowLeft } from "react-icons/fa";
import { statiPratiche } from "../data/statiPratiche";
import { useState, useEffect } from 'react'
import { adempimenti as adempimentiData } from "../data/adempimenti";
import {
  useParams
} from "react-router-dom";
import AdempimentiRow from "../components/adempimentiRow";
import AdempimentiRowReadOnly from "../components/adempimentiRorReadOnly";

import { getAdempimentiByIdPratica, getPraticaById, insertAdempimento, updatePratica, updateAdempimento, insertPraticaDetails } from "../services/pratiche_service";
import { sendNotificationToClient } from "../services/email_service";
import { useNavigate } from "react-router-dom";
import DetailModal from "../components/detailModal";
import * as yup from "yup";

function Pratica(props) {


  let { id } = useParams()

  //let {id} = url.split('&')[0]
  let idUrl = id.split('&')[0]
  let stato = parseInt(id.split('&')[1])
  const [numeroProtocollo, setNumeroProtocollo] = useState('')
  const [nomeCognome, setNomeCognome] = useState('')
  const [emailCliente, setEmailCliente] = useState('')
  const [comune, setComune] = useState('')
  const [detailOpen, setDetailOpen] = useState(false)

  const [pratica, setPratica] = useState({ idPratica: idUrl, comune: comune, creationDate: '', stato: stato, numeroProtocollo: numeroProtocollo, nomeCognome: nomeCognome, emailCliente: emailCliente })

  const [adempimenti, setAdempimenti] = useState([])
  const [adempimentiToRender, setAdempimentiToRender] = useState([])

  const [statoPratica, setStatoPratica] = useState(stato)

  const [testoModificaAdempimento, setTestoModificaAdempimento] = useState("");
  const [tipoModifica, setTipoModifica] = useState("");

  const [idAdempimentoModifica, setIdAdempimentoModifica] = useState(null);
  const [submittingEmail, setSubmittingEmail] = useState(false)

  const toast = useToast();
  const statuses = ['success', 'error', 'warning', 'info']

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [idAdempimentoDaEliminare, setIdAdempimentoDaEliminare] = useState(null)

  const [token, setToken] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [token])


  const handleLogout = (e) => {
    e.preventDefault();
    navigate('/');
    localStorage.removeItem('token')
  }


  const showToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      duration: 5000,
      isClosable: true,
      status: status
    })
  }



  const getPratica = async () => {
    const respPratica = await getPraticaById(id)

    if (respPratica.errore && respPratica.errore === 'true') {
      if (respPratica.messaggio.includes("401") || respPratica.messaggio.includes("403")) {
        showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
          navigate('/');
    localStorage.removeItem('token')
      } else {
        showToast(respPratica.messaggio, '', statuses[1])

      }
    } else {
      setPratica(respPratica)
      await setNumeroProtocollo(respPratica.numeroProtocollo)
      await setNomeCognome(respPratica.nomeCliente)
      await setEmailCliente(respPratica.emailCliente)
    }
    //console.log("pratica1", respPratica)



  }


  const getData = async () => {
    const resp = await getAdempimentiByIdPratica(idUrl)
    if (!resp || resp.errore === 'true') {
      if (resp.messaggio.includes("401") || resp.messaggio.includes("403")) {
        showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
          navigate('/');
    localStorage.removeItem('token')
      } else {
        showToast(resp.messaggio, '', statuses[1])

      }
    } else {
      //console.log("adempimenti in pagina", resp)

      setAdempimenti(resp)
      setAdempimentiToRender(resp)
      // //console.log("adempimenti in pagina", adempimenti)
    }


  }

  useEffect(() => {
    getData()
    //console.log("statoPratica", statoPratica)
    const user = localStorage.getItem('user')
    console.log("user",user)
    if (user === 'view_cliente') {
      navigate('/');
      localStorage.removeItem('token')
      localStorage.removeItem('user')

    }

  }, [statoPratica])

  useEffect(() => {
    getPratica()
    //console.log("pratica2", pratica)
    const user = localStorage.getItem('user')
    console.log("user",user)
    if (user === 'view_cliente') {
      navigate('/');
      localStorage.removeItem('token')
      localStorage.removeItem('user')

    }

  }, [numeroProtocollo, nomeCognome, emailCliente])


  function setData(idAdempimento, dataRegistrazione) {
    //console.log(idAdempimento)
    let match = adempimentiToRender.find((a) => a.id_adempimento === idAdempimento)
    if (match) {
      match.dataRegistrazione = dataRegistrazione
      setTestoModificaAdempimento(dataRegistrazione)
      setIdAdempimentoModifica(idAdempimento)
      setTipoModifica("dataRegistrazione")
    } else {
      //console.log("non ci sono adempimenti con questo idAdempimento")
    }
  }



  function setAdempimento(idAdempimento, testoAdempimento) {
    let match = adempimentiToRender.find((a) => a.id_adempimento === idAdempimento)
    console.log("match", testoAdempimento, "idAdempimento", idAdempimento)

    if (match) {
      match.adempimento = testoAdempimento
      setTestoModificaAdempimento(testoAdempimento)
      setIdAdempimentoModifica(idAdempimento)
      setTipoModifica("testoAdempimento")
      // updateAdempimento(idAdempimento, 'testoAdempimento', testoAdempimento)
    } else {
      console.log("non ci sono adempimenti con questo idAdempimento")

    }
  }

  function setTipoAdempimento(idAdempimento, valore) {
    let match = adempimentiToRender.find((a) => a.id_adempimento === idAdempimento)
    console.log("match", valore, "idAdempimento", idAdempimento)

    if (match) {
      match.tipo_adempimento = parseInt(valore)
      setTestoModificaAdempimento(parseInt(valore))
      setIdAdempimentoModifica(idAdempimento)
      setTipoModifica("tipoAdempimemto")
      // updateAdempimento(idAdempimento, 'testoAdempimento', testoAdempimento)
    } else {
      console.log("non ci sono adempimenti con questo idAdempimento")

    }
  }



  useEffect(() => {

  }, [testoModificaAdempimento, idAdempimentoModifica, tipoModifica, idAdempimentoDaEliminare]);

  const handleUpdateAdempimento = async () => {

    let adempimentToSend

    if (tipoModifica === 'dataRegistrazione') {

      adempimentToSend = {
        idPratica: parseInt(idUrl),
        dataRegistrazione: testoModificaAdempimento.toString(),
        deleted: null,
        stato_adempimento: statoPratica,

      }
    } else if (tipoModifica === 'testoAdempimento') {
      adempimentToSend = {
        idPratica: parseInt(idUrl),
        deleted: null,
        adempimento: testoModificaAdempimento,
        stato_adempimento: statoPratica,

      }
    } else if (tipoModifica === 'tipoAdempimemto') {
      adempimentToSend = {
        idPratica: parseInt(idUrl),
        deleted: null,
        stato_adempimento: statoPratica,
        tipo_adempimento: parseInt(testoModificaAdempimento)

      }
    }

    if (tipoModifica !== '') {
      const res = await updateAdempimento(adempimentToSend, idAdempimentoModifica)

      if (!res || res.errore === 'true') {
        if (res.messaggio.includes("401") || res.messaggio.includes("403")) {
          showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
            navigate('/');
    localStorage.removeItem('token')
        } else {
          showToast(res.messaggio, '', statuses[1])

        }

      } else {
        //console.log("updateAdempimento", res)
        getData()
      }
    }



  }



  const handleModal = (id) => {
    console.log("idadempimento", id)
    setIdAdempimentoDaEliminare(id)
    onOpen()
  }


  const handleEliminaAdempimento = async () => {

    console.log("idAdempimentoDaEliminare", idAdempimentoDaEliminare)
    let match = adempimentiToRender.find((ad) => {
      return ad.id_adempimento === parseInt(idAdempimentoDaEliminare)
    })
    //console.log("match", match, "id", id)
    if (match) {
      onClose()
      let today = new Date().toISOString()
      let adempimentoToSend = {
        idPratica: parseInt(idUrl),
        deleted: today,
        adempimento: match.adempimento,
        dataRegistrazione: match.dataRegistrazione,
        stato_adempimento: statoPratica,
        tipo_adempimento: match.tipo_adempimento

      }
      //console.log("adempimentoToSend", adempimentoToSend)

      const res = await updateAdempimento(adempimentoToSend, idAdempimentoDaEliminare)

      if (!res || res.errore === 'true') {
        if (res.messaggio.includes("401") || res.messaggio.includes("403")) {
          showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
            navigate('/');
    localStorage.removeItem('token')
        } else {
          showToast(res.messaggio, '', statuses[1])

        }
      } else {
        showToast(res.messaggio, '', statuses[0])

        //console.log("updateAdempimento", res)
        getData()
      }
      //console.log("elimina", res)

    }


  }


  const adempimenti_data =

    adempimentiToRender.filter((adempimento) => {
      return adempimento.idPratica === parseInt(idUrl) && adempimento.stato_adempimento === statoPratica
    }).map((d) => (
      <AdempimentiRow key={d.id_adempimento + 'tr'} idAdempimento={d.id_adempimento}
        id={d.idPratica + '-' + d.id_adempimento} dataA={d.dataRegistrazione}
        eliminaAdempimento={handleModal} adempimento={d.adempimento}
        confirm={handleUpdateAdempimento} setData={setData} setAdempimento={setAdempimento}
        tipoAdempimento={d.tipo_adempimento} setTipoAdempimento={setTipoAdempimento} >

      </AdempimentiRow>


    ));


  const onUpdatePratica = async (oggettoPratica) => {
    //setShowSpinner(true)

    // console.log("oggettoPratica", oggettoPratica)
    try {
      const res = await updatePratica(oggettoPratica, idUrl)
      //console.log("res", res)

      if (res.errore === "false") {

        showToast(res.messaggio, '', statuses[0])
      } else {
        if (res.messaggio.includes("401") || res.messaggio.includes("403")) {
          showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
            navigate('/');
    localStorage.removeItem('token')
        } else {
          showToast(res.messaggio, '', statuses[1])

        }
      }

    } catch (error) {
      showToast(error.messaggio, '', statuses[1])

      //console.log("error submit", error)

    }

  }


  const addAdempimento = async () => {
    const defaultTipoAdempimento = 0
    // //console.log(adempimenti)
    console.log("questo", statoPratica)

    const a = {
      idPratica: parseInt(idUrl),
      // dataRegistrazione: new Date().toLocaleDateString(),
      //adempimento: statoPratica !== 0 ? `${statiPratiche[statoPratica - 1].nome}:` : '',
      adempimento: '',

      deleted: null,
      stato_adempimento: parseInt(statoPratica),
      tipo_adempimento: defaultTipoAdempimento


    }
    //console.log(a)

    // setAdempimentiToRender(adempimentiToRender => [...adempimentiToRender, a])
    const response = await insertAdempimento(a)

    if (response.errore === "false") {
      showToast(response.messaggio, "", statuses[0]);
      getData()
    } else {
      if (response.messaggio.includes("401") || response.messaggio.includes("403")) {
        showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
          navigate('/');
    localStorage.removeItem('token')
      } else {
        showToast(response.messaggio, '', statuses[1])

      }
      //console.log("risposta in pagina", response)

    }


  }

  const { activeStep, setActiveStep } = useSteps({
    index: 1 * stato,
    count: statiPratiche.length,
    initialStep: 0,
  });

  const registerStep = (index) => {

    //console.log("index", index + 1)
    setStatoPratica(index + 1)
    //console.log("statoPratica", statoPratica)

    return setActiveStep((index + 1))
  }

  const handleEmail = async (idPratica) => {

    const resp = await sendNotificationToClient(idPratica)

    if (resp.errore === "false") {

      showToast(resp.messaggio, "", statuses[0]);



      /*    const templateParams = {
           nomeCliente: nomeCognome,
           message: 'messaggio con il link',
           email_from: emailCliente,
           from_name: '  DeSimone&Partners'
         };
   
          emailjs.send('service_gjjigde', 'template_xt65x3f', templateParams, 'mFq0K9RU3y-wohBGR')
           .then((response) => {
             //console.log('SUCCESS!', response.status, response.text);
             showToast(resp.messaggio, "", statuses[0]);
   
           }, (err) => {
             //console.log('FAILED...', err);
             showToast(resp.messaggio, "", statuses[1]);
   
           });  */

    } else {
      if (resp.messaggio.includes("401") || resp.messaggio.includes("403")) {
        showToast("Sessione scaduta, fare nuovamente il login", '', statuses[1])
          navigate('/');
    localStorage.removeItem('token')
      } else {
        showToast(resp.messaggio, '', statuses[1])

      }

    }

    //console.log("risposta handleEmail in pagina", resp)
    //console.log("email", emailCliente)

  }
  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = 'Required';
  //   } else if (!/^[A-z0-9\.\+-]+@[A-z0-9\.-]+\.[A-z]{2,6}$/i.test(value)) {
  //     error = 'Invalid email address';
  //   }
  //   return error;
  // }

  const schema = yup.object().shape({
    emailCliente: yup.string().email('Invalid email address')
});

console.log(ErrorMessage)
  return (
    <>

      <Stack w='100vw' h='100vh' >
        <Card size='lg' w='100wv' h='100hv' >
          {(token && token !== '' && token !== undefined) ?
            <Flex alignContent={'center'} justifyContent={'space-between'} bgColor={'#584034'} p={3} mb={3}>
               <Button
                _hover={{ bg: "#8a4828" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/home');
                }}
               isRound={true}
                background={'white'} color={'#584034'}
                > <FaArrowLeft ></FaArrowLeft>Torna alle Pratiche</Button>
              <Link color={'white'} href="#" onClick={ (e)=>handleLogout(e)}>Disconnetti</Link>
            </Flex> : <></>}
          {detailOpen ? <DetailModal isOpen={detailOpen} onClose={setDetailOpen}></DetailModal> : ''}

          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay  />
            <ModalContent>
              <ModalHeader>Attenzione</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6} >
                <p>
                  Sei sicuro di voler eliminare questo Adempimento?
                </p>
              </ModalBody>

              <ModalFooter>
                <Button _hover={{ bg: "#8a4828" }} background={'#584034'} color={'white'} colorScheme='brand' mr={3} onClick={handleEliminaAdempimento}>
                  Elimina
                </Button>
                <Button onClick={onClose}>Annulla</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Grid templateColumns='repeat(3, 1fr)' gap={6} padding={'1.25rem'} alignItems={"center"}>
            <GridItem/>
            <GridItem>
            <Stepper borderRadius={15} background={'#C69E81'} padding={10} index={activeStep} colorScheme="blackAlpha">
              {statiPratiche.map((step, index) => (
                <Step key={index} onClick={() => registerStep(index)} >
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink='2'>

                    <StepDescription color={'black'} >{step.nome}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            </GridItem>
            <GridItem textAlign={"end"}>
              <IconButton loadingText='Sto inviando'
                isLoading={submittingEmail} _hover={{ bg: "#8a4828" }} onClick={() => { handleEmail(idUrl) }} padding={7} isRound={true} size={'lg'} marginLeft={5} background={'#584034'} color={'white'} icon={<FaArrowRight ></FaArrowRight>} width={'200px'}></IconButton>
              <Text marginLeft={5}>Invia Aggiornamenti al Cliente</Text>
            </GridItem>
          </Grid>
          

          <Card marginBottom={5} padding={5} w='100wv' h='100hv'>
           {/* button per modale altri dati cliente */}
          <Stack display={'flex'} justifyContent={'end'} alignItems={'end'}>
            <Button type="button" _hover={{ bg: "#8a4828", color:"white" }} mt={'2rem'} mb={'1.5rem'} border={'1px solid #584034'} color={'#584034'} onClick={() => setDetailOpen(!detailOpen)} display={'flex'} gap={'10px'}><FaRegStickyNote/><p>Visualizza Altri Dati</p></Button>
          </Stack>
            <Formik
              validationSchema={schema}
              enableReinitialize
              initialValues={{ protocollo: numeroProtocollo, nomeCognome: nomeCognome, emailCliente: emailCliente, comune: comune }}
              onSubmit={(values, actions) => {
                let idPratica
                //  actions.setValues(numeroProtocollo, nomeCognome, emailCliente)
                if (parseInt(idUrl) !== 0) {
                  idPratica = idUrl
                } else {
                  idPratica = null
                }
                let oggettoPratica = {
                  numeroProtocollo: values.protocollo,
                  nomeCliente: values.nomeCognome,
                  emailCliente: values.emailCliente,
                  stato: statoPratica,
                  comune: values.comune
                }
                actions.setSubmitting(true)
                setNumeroProtocollo(values.protocollo)
                setNomeCognome(values.nomeCognome)
                setEmailCliente(values.emailCliente)
                setComune(values.comune)
                //console.log("pratica in locale", oggettoPratica)

                onUpdatePratica(oggettoPratica)
                actions.resetForm()



              }}
            >
              {(props, validateField, errors, touched) => (
                <Form direction='row'>
                  <Flex justifyContent={'space-between'}>
                    <Field name='protocollo'>
                      {({ field, form }) => (
                        <FormControl maxWidth={'450px'} margin='0px 10px 5px 0px' isInvalid={form.errors.protocollo && form.touched.protocollo}>
                          <FormLabel>Numero Protocollo Pratica

                          </FormLabel>
                          <Input focusBorderColor={"#C69E81"} value={numeroProtocollo} {...field} placeholder='Numero di Protocollo' />
                        </FormControl>
                      )}
                    </Field>
                    <Field name='nomeCognome'>
                      {({ field, form }) => (
                        <FormControl maxWidth={'450px'} margin='0px 10px 5px 0px' isInvalid={form.errors.nomeCliente && form.touched.nomeCognome}>
                          <FormLabel>Nome della Famiglia  </FormLabel>
                          <Input focusBorderColor={"#C69E81"} value={nomeCognome} {...field} placeholder='Cognome' />
                        </FormControl>
                      )}
                    </Field>
                    <Field name='emailCliente'>
                      {({ field, form }) => (
                        <FormControl maxWidth={'450px'} margin='0px 10px 5px 0px' isInvalid={form.errors.emailCliente && form.touched.emailCliente}>
                          <FormLabel>Email Cliente</FormLabel>
                          <Input focusBorderColor={"#C69E81"} value={emailCliente} {...field} placeholder='Email Cliente' />
                        </FormControl>
                      )}
                    </Field>
                    <Button w={250}
                      mt={8}
                      _hover={{ bg: "#8a4828" }}
                      background={'#584034'} color={'white'}
                      loadingText='Compila i campi per salvare'
                      type='submit'
                    >
                      Salva Dati Pratica
                    </Button>
                  </Flex>


                  <Flex
                  justifyContent={"start"}>
                      {activeStep === 3 ? (
                        <Field name='comune'>
                          {({ field, form }) => (
                            <FormControl margin='0px 10px 5px 0px' isInvalid={form.errors.comune && form.touched.comune} width={'450px'}>
                              <FormLabel>Comune </FormLabel>
                              <Input focusBorderColor={"#C69E81"} value={comune} {...field} placeholder='Comune' />
                            </FormControl>
                          )}
                        </Field>
                      ) : (<></>)}
                  </Flex>
               

                </Form>
              )}
            </Formik>

          </Card >
          <hr></hr>
          <CardHeader align='center'>
            <Heading size='lg'>Adempimenti {(statiPratiche[statoPratica - 1]) ? '- ' + statiPratiche[statoPratica - 1].nome : ''}</Heading>
          </CardHeader>
          <Flex>
            <IconButton type="button" _hover={{ bg: "#8a4828" }} margin='20px' background={'#584034'} color={'white'} icon={<FaPlus></FaPlus>} onClick={addAdempimento}></IconButton>
            <TableContainer w='100vw' overflowY={'block'} h='45vh' padding='20px'>
              <Table variant="striped" colorScheme="brand">

                <Thead>
                  <Tr >
                    <Th>Data</Th>
                    <Th> Attività</Th>
                    <Th>Adempimenti</Th>

                    <Th> Azioni</Th>
                  </Tr>
                </Thead>
                <Tbody>{adempimenti_data}</Tbody>

              </Table>
            </TableContainer>
          </Flex>

          {/*           {<Link
            onClick={(e) => {
              e.preventDefault();
              navigate("/praticaReadOnly/" + id);
            }}

            href={'#'}>Vai visualizzazione Cliente</Link>
          } */}
        </Card>

      </Stack>

    </>
  );
}

export default Pratica;
