import {
  Flex, Stack, Avatar, Heading, Box,
  FormControl, InputGroup, InputLeftElement, Input
  , InputRightElement, Button, Image, FormLabel, useToast
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { tryLogin } from "../services/login_service";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const statuses = ['success', 'error', 'warning', 'info']

  const showToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      duration: 5000,
      isClosable: true,
      status: status
    })
  }

  const handleShowClick = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const handleLogin = async (datiUtente) => {
    try {
      const response = await tryLogin(datiUtente);
      const token = response.token;

      // Store the tokens in localStorage or secure cookie for later use
      localStorage.setItem('token', token);
      localStorage.setItem('user',datiUtente.username)
      // Redirect or perform other actions upon successful login

      if (response.messaggio === true) {
        navigate('/home')
      } else {
        showToast(response.messaggio, '', statuses[1])
      }
    } catch (error) {
      // Handle login error
      showToast(error.messaggio, '', statuses[1])

    }

  }
  return (

    <>
      <Box
        bg={'#030303'} backgroundPosition="center"
        backgroundRepeat="no-repeat"
        bgSize={'cover'}>

        <Flex
          flexDirection="column"
          width="100wh"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            flexDir="column"
            justifyContent="center"
            alignItems="center">
            <Image src="logo-1000-210.png" fallbackSrc='https://via.placeholder.com/150'>

            </Image>
          </Stack>

          <Stack
            flexDir="column"
            spacing={4}
            p="1rem"
            justifyContent="center"
            boxShadow="md"
            alignItems="center"
          >
            <Heading color="#C69E81">Benvenuto</Heading>
            <Box minW={{ base: "90%", md: "468px" }}>

              <Formik
                onSubmit={(values, { setSubmitting }) => {
                  handleLogin(values, setSubmitting)
                  setSubmitting(false)

                }}
                initialValues={{ username: '', password: '' }}>
                {({ isSubmitting }) => (
                  <Form>
                    <Stack spacing={4}
                      p="1rem"
                      backgroundColor="white"
                      boxShadow="md">

                      <Field name='username'>
                        {({ field, form }) => (
                          <FormControl>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<FaUserAlt color="gray.400" />}
                              />
                              <Input type="text" placeholder="email address" {...field} />
                            </InputGroup>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='password'>
                        {({ field, form }) => (
                          <FormControl>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                color="gray.300"
                                children={<FaLock color="gray.400" />}
                              />
                              <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password" {...field}
                              />
                              <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                                  {showPassword ? "Hide" : "Show"}
                                </Button>
                              </InputRightElement>
                            </InputGroup>

                          </FormControl>
                        )}
                      </Field>
                      <Button
                        isLoading={isSubmitting}
                        _hover={{ bg: "#8a4828" }}
                        background={'#584034'} color={'white'}
                        loadingText={'in caricamento'}
                        size={'lg'}
                        type=""
                      >
                        Login
                      </Button>
                    </Stack>

                  </Form>
                )}
              </Formik>
              {/*    <form onSubmit={()=>handleLogin}>
                <Stack
                  spacing={4}
                  p="1rem"
                  backgroundColor="white"
                  boxShadow="md"
                >
                  <FormControl>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FaUserAlt color="gray.400" />}
                      />
                      <Input type="email" placeholder="email address" />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        children={<FaLock color="gray.400" />}
                      />
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                          {showPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
          
                  </FormControl>
                  <Button
                    borderRadius={0}
                    type="submit"
                    variant="solid"
                    _hover={{ bg: "#8a4828" }}
                    background={'#584034'} color={'white'}
                    width="full"
                  >
                    Login
                  </Button>
                </Stack>
              </form> */}
            </Box>
          </Stack>
          {/* <Box>
          New to us?{" "}
          <Link color="blue.500" href="#">
            Sign Up
          </Link>
        </Box> */}
        </Flex>
      </Box>

    </>
  );
}

export default Login;
