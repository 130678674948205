export const statiPratiche = [
    {
      value:"1",
      nome: "Apertura Pratica",
      
    },
    {
        value:"2",
        nome: "Processo Giudiziale",
        
      },
      {
        value:"3",
        nome: "Trascrizione",
        
      },
    
  ];