export const tipi_adempimento = [
    {
      value:0,
      nome: "Nessuna Attività Selezionata",
      
    },
    {
        value:10,
        nome: "PROTOCOLLATO",
        
      },
      {
        value:20,
        nome: "NOTIFICATO E CARICATO",
        
      },
      {
        value:30,
        nome: "DEPOSITO NOTE",
        
      },
      {
        value:40,
        nome: "SENTENZA",
        
      },
      {
        value:50,
        nome: "RICHIESTO PIG",
        
      },
      {
        value:60,
        nome: "RILASCIATO PIG",
        
      },
      {
        value:70,
        nome: "INVIO  AL COMUNE",
        
      },
      {
        value:80,
        nome: "SOLLECITO",
        
      },
      {
        value:90,
        nome: "DIFFIDA",
        
      }
    
  ];

