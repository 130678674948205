import { Center, ChakraProvider, Stack, Link } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Pratica from "./pages/pratica";
import PraticaReadOnly from "./pages/praticaReadOnly";
import emailCliente from "./components/emailCliente";
import React, { useEffect, useState } from "react"
import { extendTheme } from "@chakra-ui/react"
import { fetchManifest, manifestdata } from "./services/pratiche_service";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#C69E81",
      // ...
      900: "#1a202c",
    },
  },
  components: {
    Stepper: {
      baseStyle: {
        indicator: {
          "&[data-status=incomplete]": {
            borderColor: "rgba(0, 0, 0, 0.36);"
          }
        },
        separator: {
          display: 'none',
        },
      }
    }
  }
})


function App() {
  const [token, setToken] = useState('');


  let manifest = ''

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [token])


  useEffect(() => {
       var responseClone; // 1
    fetch("/manifest.json")
      .then(function (response) {
        console.log("manifestName", manifest)

        responseClone = response.clone(); // 2
        return response.json();
      })
      .then(function (manifest) {
        // Do something with data
        console.log("manifest", manifest)
        window.manifest = manifest

        console.log('window.manifest', window.manifest); // 6

      }, function (rejectionReason) { // 3
        console.log('Error parsing JSON from response:', rejectionReason, responseClone); // 4
        responseClone.text() // 5
          .then(function (bodyText) {
            console.log('Received the following instead of valid JSON:', bodyText); // 6
          });
      });
  })


  const handleLogout = () => {
    console.log("ciao")
    localStorage.removeItem('token')
  }

    return (
      <>
        <ChakraProvider theme={theme}>
        
          <BrowserRouter>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Login />}>
                <Route index element={<Login />} />
              </Route>
              <Route path="/pratica/:id" element={<Pratica />} />
              <Route path="/praticaReadOnly/:id" element={<PraticaReadOnly />} />
              <Route path="/email" element={<emailCliente />} />

            </Routes>

          </BrowserRouter>
        </ChakraProvider>
      </>
    )
}

export default App;
