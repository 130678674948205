import Pratiche from "./pratiche";
import { Flex, Stack } from "@chakra-ui/react";

function Home() {
  return (
    <>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Pratiche />
        </Stack>
      </Flex>
    </>
  );
}

export default Home;
