import { api } from "./axios";


export const getPraticaByGuid = async (guid) => {

    console.log("parametri getPraticheByGuid nel service", guid)

    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
                'Content-Type': 'application/json'
            }
        };
        console.log("process.env.PL_BASE_URL", process.env.REACT_APP_BASE_URL)
        let url = `/rpc/get_pratica_byguid`
        let objToSend = {
            gu: guid,
        }
        const response = await (await api.post(url, objToSend, config)).data

        console.log("res getPraticheByGuid", response)

        let risposta
        if (response) {
            risposta = {
                errore: "false",
                data: JSON.parse(response),
                messaggio:"tutto okk"
            }
        } else {
            risposta = {
                errore: "true",
                messaggio: `Qualcosa non va: ${response}`
            }
        }

        // console.log("risposta tryLogin", risposta)

        return risposta

    } catch (error) {
        console.log("error tryLogin", error)
        let risposta
        risposta = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }

        return risposta
    }
}

export const getAdempimentiByGuid = async (guid) => {
    console.log("parametri getAdempimentiByGuid nel service", guid)

    try {
        const config = {
            headers: {
                Prefer: `return=representation`,
                'Content-Type': 'application/json'
            }
        };
        console.log("process.env.PL_BASE_URL", process.env.REACT_APP_BASE_URL)
        let url = '/rpc/get_adempimenti_by_guid'
        let objToSend = {
            gu: guid,
        }
        const response = await (await api.post(url, objToSend, config)).data

        console.log("res getAdempimentiByGuid", response)

        let risposta;

       let arrayAdempimenti1 = []
       let arrayAdempimenti = []

       response.forEach(element => {

        arrayAdempimenti1.push(element)
        
       });

       arrayAdempimenti1.forEach(element => {

        if( element.deleted == null){
            arrayAdempimenti.push(element.r)

        }
        
       });

       console.log("res getAdempimentiByGuid", arrayAdempimenti)

        if (response) {
            risposta = arrayAdempimenti
        } else {
            risposta = {
                errore: "true",
                messaggio: `Qualcosa non va: ${response}`
            }
        }

        // console.log("risposta tryLogin", risposta)

        return risposta

    } catch (error) {
        console.log("error getAdempimentiByGuid", error)
        let risposta
        risposta = {
            errore: "true",
            messaggio: `Qualcosa non va: ${error}`
        }

        return risposta
    }
}