import axios from "axios"
import configData from "../data/configuration.json"
import configDataDev from "../data/configuration_dev.json"
import configDataTest from "../data/configuration_test.json"

let manifestdata;

if (process.env.NODE_ENV === "production") {
    manifestdata = configData
}
if (process.env.NODE_ENV === "development") {
    manifestdata =  configDataDev
}
if (process.env.NODE_ENV === "test") {
    manifestdata = configDataTest
}

const api = axios.create({
   // baseURL: process.env && process.env.REACT_APP_BASE_URL+':'+process.env.REACT_APP_PORT,
   baseURL: process.env && process.env.REACT_APP_BASE_URL

  // baseURL:window.manifest && window.manifest.dev.base_url
})

const apiLogin = axios.create({
 // baseURL:process.env && process.env.REACT_APP_BASE_URL+':'+process.env.REACT_APP_AUTH_PORT
 baseURL: process.env && process.env.REACT_APP_AUTH_URL
})
 
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );



export { api }
export {apiLogin}
