import {
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer,
    Heading,
    Stack,
    Box,
    Card,
    Text,
    CardHeader,
    IconButton,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    Stepper,
    useSteps,
    FormControl,
    FormLabel,
    Input,
    Button,
    Flex,
    Center,
    useToast,
    Image
} from "@chakra-ui/react";
import {
    Formik, Form, Field,
} from "formik";


import { pratiche } from "../data/pratiche";
import { FaEdit, FaPlus, FaArrowRight } from "react-icons/fa";
import { statiPratiche } from "../data/statiPratiche";
import { useState, setState, useEffect } from 'react'
import {
    useParams, useNavigate, useLocation
} from "react-router-dom";
import { getPraticaByGuid, getAdempimentiByGuid } from "../services/praticheReadonly_service";
import AdempimentiRow from "../components/adempimentiRow";
import AdempimentiRowReadOnly from "../components/adempimentiRorReadOnly";
import { countingVisits } from "../services/email_service";
import { tryLogin } from "../services/login_service";

function PraticaReadOnly(props) {


    const [numeroProtocollo, setNumeroProtocollo] = useState('')
    const [nomeCognome, setNomeCognome] = useState('')
    const [emailCliente, setEmailCliente] = useState('')
    const [statoPratica, setStatoPratica] = useState()
    const navigate = useNavigate();
    const location = useLocation();

    const [pratica, setPratica] = useState({})
    // let { guid1 } = useParams()
    let guid = useParams().id
    console.log("parametro guid", guid)


    let datiUtenteGuest = {
        username: 'view_cliente',
        password: 'NTu5PD3bL7Vc'
    }


    let stato;
    let idPratica;

    const [adempimenti, setAdempimenti] = useState([])
    const [adempimentiToRender, setAdempimentiToRender] = useState([])


    const toast = useToast();
    const statuses = ['success', 'error', 'warning', 'info']

    const showToast = (title, description, status) => {
        toast({
            title: title,
            description: description,
            duration: 5000,
            isClosable: true,
            status: status
        })
    }

    const handleLogin = async (datiUtente) => {
        try {
            const response = await tryLogin(datiUtente);
            const token = response.token;

            // Store the tokens in localStorage or secure cookie for later use
            localStorage.setItem('token', token);
            localStorage.setItem('user',datiUtente.username)

            // Redirect or perform other actions upon successful login

            if (response.messaggio === true) {
                getPratica(guid);
                getData();
            } else {
                showToast(response.messaggio, '', statuses[1])
            }
        } catch (error) {
            // Handle login error
            showToast(error.messaggio, '', statuses[1])

        }

    }

    const getPratica = async (parametroGuid) => {
        const respPratica = await (await getPraticaByGuid(parametroGuid)).data

        if (respPratica.errore && respPratica.errore === 'true') {
            showToast(respPratica.messaggio, '', statuses[1])

        } else {
            const rispostaCounting = await countingVisits(respPratica.idPratica, guid)
            console.log("rispostaCounting in pagina", rispostaCounting)

            setPratica(respPratica)
            await setNumeroProtocollo(respPratica.numeroProtocollo)
            await setNomeCognome(respPratica.nomeCliente)
            await setEmailCliente(respPratica.emailCliente)
            await setStatoPratica(respPratica.stato)
            stato = respPratica.stato
            idPratica = respPratica.idPratica
            setActiveStep(respPratica.stato)
        }
        console.log("pratica1", respPratica)



    }

    const getData = async () => {
        const resp = (await getAdempimentiByGuid(guid))

        console.log("response adempimenti", resp)
        if (!resp || resp.errore === 'true') {
            showToast(resp.messaggio, '', statuses[1])
        } else {
            console.log("adempimenti in pagina", adempimenti)
            setAdempimenti(resp)
            setAdempimentiToRender(resp)



        }


    }

    function setData(idAdempimento, data) {


        console.log(adempimentiToRender)

        let match = adempimentiToRender.find((a) => a.id_adempimento === parseInt(idAdempimento))
        if (match) {
            match.dataRegistrazione = data

        } else {
            console.log("non ci sono adempimenti con questo idAdempimento")
        }
    }

    function setAdempimento(idAdempimento, adempimento) {


        console.log(idAdempimento)
        let match = adempimentiToRender.find((a) => a.id_adempimento === parseInt(idAdempimento))
        if (match) {
            match.adempimento = adempimento

        } else {
            console.log("non ci sono adempimenti con questo idAdempimento")
        }
    }


    const adempimenti_data =

        adempimentiToRender.map((d) => (

            <AdempimentiRowReadOnly key={d.id_adempimento + 'tr'} idAdempimento={d.id_adempimento} id={d.idPratica + '-' + d.id_adempimento} dataA={d.dataRegistrazione} adempimento={d.adempimento} tipoAdempimento={d.tipo_adempimento} setData={setData} setAdempimento={setAdempimento} >

            </AdempimentiRowReadOnly>


        ));

    useEffect(() => {
        //     console.log("localStorage.getItem('token')",localStorage.getItem('token'))

        handleLogin(datiUtenteGuest)



    }, [guid])



    useEffect(() => {
        if (localStorage.getItem('token') !== undefined) {

        } else {
            navigate('/');
            localStorage.removeItem('token')
        }

    }, [statoPratica])

    const logout = () => {
        navigate('/');
        localStorage.removeItem('token')
    }

    useEffect(() => {
        return () => {
            logout(); // Chiama la funzione di logout quando il componente viene smontato
        };
    }, []);


    useEffect(() => {
        console.log("navigate",location.pathname)

        const unlisten = navigate(() => {
            logout(); // Esegui il logout quando si cambia pagina
        });
        return unlisten; // Rimuovi il listener quando il componente viene smontato
    }, [navigate]);

    useEffect(() => {
     // Esegui il logout quando l'URL della pagina viene cambiato manualmente
        console.log("primo",location.pathname)
        
        if(location.pathname.includes('praticaReadOnly') === false){
            logout(); 
        }
    }, [location.pathname]);

    const { activeStep, setActiveStep } = useSteps({
        index: stato,
        count: statiPratiche.length,
        initialStep: 0,
    });


    return (
        <>

            <Stack w='100vw' h='100vh' >
                <Card size='lg' w='100wv' h='100hv' margin='10px'>
                    <Center>
                        <Image src="..\logo-1000-210.png" >

                        </Image>
                    </Center>
                    <Center>

                        <Stepper borderRadius={15} background={'#C69E81'} padding={10} index={activeStep} colorScheme="blackAlpha">
                            {statiPratiche.map((step, index) => (
                                <Step key={index} >
                                    <StepIndicator >
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='2'>
                                        <StepDescription color={'black'} key={step.nome} >{step.nome}</StepDescription>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>

                    </Center>


                    <Card key={'cardForm'} padding={5} w='100wv' h='100hv'>
                        <Flex direction={['column', 'row']} justify='center' alignItems={'center'}>
                            <FormControl key={'numeroProtocollo'} margin='0px 10px 5px 0px' >
                                <FormLabel>Numero Protocollo Pratica

                                </FormLabel>
                                <Input focusBorderColor={"#C69E81"} readOnly defaultValue={numeroProtocollo} placeholder='Numero di Protocollo' />
                            </FormControl>


                            <FormControl key={'nomeCognome'} margin='0px 10px 5px 0px' >
                                <FormLabel>Nome della Famiglia   </FormLabel>
                                <Input focusBorderColor={"#C69E81"} readOnly defaultValue={nomeCognome} placeholder='Nome e Cognome' />
                            </FormControl>


                            <FormControl key={'email'} margin='0px 10px 5px 0px' >
                                <FormLabel>Email Cliente </FormLabel>
                                <Input focusBorderColor={"#C69E81"} readOnly defaultValue={emailCliente} placeholder='Email Cliente' />
                            </FormControl>


                        </Flex>



                    </Card >
                    <CardHeader align='center'>
                        <Heading size='lg'>Adempimenti</Heading>
                    </CardHeader>
                    <Flex direction={['column', 'row']} justify='center' align='center'>
                        <Box flex='1'>
                            <TableContainer w='100vw' h='100vh' padding='20px'>
                                <Table variant="striped" colorScheme="brand" >
                                    <Thead>
                                        <Tr>
                                            <Th className={'largeTable'}>Data</Th>
                                            <Th className={'largeTable'}>Attività</Th>
                                            <Th className={'largeTable'}>Adempimenti</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>{adempimenti_data}</Tbody>
                                </Table>

                            </TableContainer>
                        </Box>

                    </Flex>


                </Card>

            </Stack>

        </>
    );
}

export default PraticaReadOnly;
