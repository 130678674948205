import {
    Tr,
    Td,
    Stack,
    IconButton,
    Select,
    Input
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaCheck } from "react-icons/fa";
import { dateConverterFromStringToDate } from "../services/utilities";
import { tipi_adempimento } from "../data/tipi_adempimento";


function AdempimentiRow(props) {

    const [isInEdit, setIsInEdit] = useState(false)

    // const handleEdit = () => {
    //     setIsInEdit(!isInEdit)
    //     // document.getElementById(props.id + 'adempimento').focus();
    //     if (isInEdit) {
    //         props.confirm()
    //     }
    // }
    const handleEdit = () => {
            props.confirm()
    }
    const convertDateToLocale = (data) => {
        return data.split('T')[0].toString()
    }

    // useEffect(() => {
    // if(props.adempimento == '')
    // setIsInEdit(!isInEdit)
    //  document.getElementById(props.id + 'adempimento').focus();
    
    //   }, [])

    



    return (

        <Tr>

            <Td w={'100px !important'}>
                <Input type="date" focusBorderColor={"#584034"}
                    bg={'lightcyan.100'} key={props.id + 'data'}
                    // readOnly={!isInEdit} 
                    defaultValue={convertDateToLocale(props.dataA)}
                    onChange={(e) => {
                        e.preventDefault()
                        props.setData(props.idAdempimento, e.target.value)
                    }} variant='outline' placeholder='Inserire Data' 
                    onBlur={() => handleEdit()}/>
            </Td>
            <Td w={'300px !important'}>
                <Select
                    focusBorderColor={"#584034"} bg={'lightcyan.100'}
                    defaultValue={(tipi_adempimento.find((a) => a.value === parseInt(props.tipoAdempimento))) ? tipi_adempimento.find((a) => a.value === parseInt(props.tipoAdempimento)).value : tipi_adempimento[0].value}
                    id={props.id + 'tipoAdempimento'}
                    key={props.id + 'tipoAdempimento'}
                    // isDisabled={!isInEdit}
                    onBlur={() => handleEdit()}
                    onChange={(e) => {
                        console.log("select", e)
                        props.setTipoAdempimento(props.idAdempimento, e.target.value)
                    }}>
                    {tipi_adempimento.map((tipo) => {
                        return <option value={tipo.value}>{tipo.nome}</option>

                     /*    if (tipo.value === 0) {
                            return <option value={tipo.value} disabled>{tipo.nome}</option>
                        } else {
                            return <option value={tipo.value}>{tipo.nome}</option>
                        }
 */
                    })}

                </Select>

            </Td>
            <Td >
                <Input focusBorderColor={"#584034"} bg={'lightcyan.100'}
                    id={props.id + 'adempimento'} key={props.id + 'adempimento'}
                    // readOnly={!isInEdit} 
                    defaultValue={props.adempimento}
                    onChange={(e) => {
                        console.log("ff", e.target.value)
                        e.preventDefault()

                        props.setAdempimento(props.idAdempimento, e.target.value)
                    }} variant='outline' placeholder='Inserire Adempimento' 
                    onBlur={() => handleEdit()}/>
            </Td>

            <Td w={'2rem'}>
                <Stack direction={"row"}>
                    {/* <IconButton _hover={{ bg: "#8a4828" }} background={'#584034'}
                        id={props.id + 'bottoneModificaAdempimento'} color={'white'}
                        icon={(!isInEdit) ? <FaEdit></FaEdit> : <FaCheck></FaCheck>}
                        onClick={() => handleEdit()}></IconButton> */}
                    <IconButton colorScheme='red' icon={<FaTrash></FaTrash>}
                        onClick={() => { props.eliminaAdempimento(props.idAdempimento) }}></IconButton>

                </Stack>

            </Td>
        </Tr>

    );
}

export default AdempimentiRow;

