import { useToast } from "@chakra-ui/react";

export const executeFilter = (items, filterParams) => {

    let filteredItems=[];

    items.forEach((i)=>{

        if(filterSearch(i, filterParams) == true){

            filteredItems.push(i);
        }
    });
    
    return filteredItems;
}

/* filterParams = {
    fieldList:[''],
    searchString:''
}
 */
export const filterSearch = (item, filterParams) => {
    let searchFieldsList = filterParams.fieldList;
    let searchString = filterParams.searchString;
    if (searchString.trim() == "") {
      return true;
    }

    for (let index = 0; index < searchFieldsList.length; index++) {
      const sf = searchFieldsList[index];

      let fieldValue = "";

      fieldValue = item[sf];

      let matchfield;
      //metto questa verifica in caso uno dei campi di ricerca sia null
     if (fieldValue != null) {
        matchfield = fieldValue
          .toString()
          .toLowerCase()
          .trim()
          .includes(searchString.toLowerCase().trim());
      }

      if (matchfield) {
        return true;
      }
    }

    return false;
  }

  export function dateConverter(date) {
    const dataArray = date.split('T')
    const oraArray = dataArray[1].split(':')
    const localeDateArray = dataArray[0].split('-')
    return `${localeDateArray[2]}/${localeDateArray[1]}/${localeDateArray[0]}${dataArray[1] !== undefined ? ' ore ' : ''} ${oraArray[0]+':'+oraArray[1] || ''}`
}

export function dateConverterReadOnly(date) {
  if(date && date !== '' && date !== null && date !== 'null'){
    const dataArray = date.split('T')
    const oraArray = dataArray[1].split(':')
    const localeDateArray = dataArray[0].split('-')
    return `${localeDateArray[2]}/${localeDateArray[1]}/${localeDateArray[0]}`
  } else {
    return ''
  }

}

export function dateConverterFromStringToDate(dateString) {
    const formatDateArray = dateString.split('/')
    return `${formatDateArray[2]}-${formatDateArray[1]}-${formatDateArray[0]}`

}
